import { Formik, Form } from "formik"
import { InputControl, SubmitButton } from "formik-chakra-ui"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { Center, Box, VStack } from "@chakra-ui/react"
import LocalizedStrings from "react-localization"

const schema = yup.object().shape({
    // confirmationNo must be a string with a length between 28 and 36
    // and may include alphanumeric characters, hyphens, underscores, and periods
    confirmationNo: yup.string().min(28, "Invalid confirmation code").max(36, "Invalid confirmation code")
       .matches(/^[a-zA-Z0-9-_.]{28,36}$/, "Invalid confirmation code")
})

export default function ConfirmationForm() {
    const navigate = useNavigate()
    const strings = new LocalizedStrings({
        en: {
            confirmationCode: "Confirmation No.",
            enterConfirmationCode: "Enter the confirmation code",
            submit: "Submit"
        },
        ko: {
            confirmationCode: "Confirmation No.",
            enterConfirmationCode: "Confirmation No.를 입력하세요",
            submit: "OK"
        }
    })

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        console.log("values", values)
        navigate(`/confirm/${values.confirmationNo}`, )
        setSubmitting(false)
    }

    return <Center minH={"100vh"} w={"full"} bg={"bg2.50"}>
        <Center w={"full"}>
            <Box w={"full"} mx={"auto"} px={6}>
            <Formik
                initialValues={{ confirmationNo: "" }}
                onSubmit={onSubmit}
                validationSchema={schema}
            >
                {({ values }) => (
                    <Form>
                        <VStack align={"flex-end"}>
                            <InputControl
                                autoFocus={true}
                                inputProps={{ backgroundColor: "white"}}
                                labelProps={{color: "brand.600"}}
                                w={"full"}
                                name={"confirmationNo"}
                                label={strings.confirmationCode}
                                placeholder={strings.enterConfirmationCode}
                            />
                            <SubmitButton colorScheme={"brand"}>{strings.submit}</SubmitButton>
                        </VStack>

                    </Form>
                )}
            </Formik>
            </Box>
        </Center>
    </Center>
}